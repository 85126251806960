import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function Fellowship(props) {
  return (
    <>
      {/* <div className="our-mission-stripes">
        <ImageMeta
          cloudName="nuvolum"
          publicId="KTOMS/DEV/line"
          width="auto"
          responsive
        />
      </div> */}

      {/* <div className="gradient-divider"></div> */}

      <section
        className="our-mission fellowship"
        style={{ backgroundColor: "#f5f5f6" }}>
        <div
          style={{ width: "100%" }}
          className={`columns has-text-centered-tablet`}>
          <div className={`column is-${props.sideColumnIs}`}></div>
          <div
            className="column"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.fellowship)
            )}></div>
          <div className={`column is-${props.sideColumnIs}`}></div>
        </div>
      </section>

      {/* <div className="gradient-divider"></div> */}

      {/* <div className="our-mission-stripes">
        <ImageMeta
          cloudName="nuvolum"
          publicId="KTOMS/DEV/line"
          width="auto"
          responsive
        />
      </div> */}
    </>
  )
}

export default Fellowship
